<template>
  <div id="app" v-if="init">
    <TopNav v-if="!hideNav"></TopNav>
    
    <div class="content">
      <router-view/>
    </div>

    <footer>

    </footer>

    <Transition name="fade">
      <div class="loading-mask" v-if="isStoreBusy">
        <b-spinner></b-spinner>
      </div>
    </Transition>
  </div>
</template>

<script>
import TopNav from './components/TopNav';
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment';
moment.locale('zh-tw');

export default {
  name: 'AppView',
  data() {
    return {
      init: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch('auth/initLoginToken');
      this.init = true;
    } catch(e) {
      console.error(e);
    }
  },
  components: {
    TopNav,
  },
  watch: {
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'auth/hasToken']),
    ...mapState(['hideNav']),
    isLoggedIn() {
      return this['auth/hasToken'];
    },
    // routeName() {
    //   return this.$route.name;
    // },
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async loggedOut() {
      await this.$store.dispatch('auth/loggedOut');
      await this.$router.push({name: 'Login'});
    },
  }
}
</script>


<style lang="scss" scoped>

.loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0005;
  opacity: 1;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}


</style>
