import axios from 'axios';

const API_BASE_URL = '/api';

export default {
  namespaced: true,
  state: {
    URLS: {
      LOGIN: `${API_BASE_URL}/user/login`,
      LOGINLINE: `${API_BASE_URL}/user/loginByLineCode`,
      REFRESH_TOKEN: `${API_BASE_URL}/user/refresh`,

			GET_ACCOUNT_INFO: `${API_BASE_URL}/user/me`,

      GET_SURVEY_DETAIL: `${API_BASE_URL}/survey/detail`,

      POST_RECORD_ANSWERED: `${API_BASE_URL}/record/answered`,
      GET_RECORD_LIST_BY_LOGOS: `${API_BASE_URL}/record/answerList`,




      GET_LOGO_LIST: `${API_BASE_URL}/logo/list`,
      GET_PUBLIC_LOGO_LIST: `${API_BASE_URL}/logo/listPublic`,
      UPLOAD_LOGO_IMAGE: `${API_BASE_URL}/logo/uploadBase64Img`,
      POST_LOGO_CREATE: `${API_BASE_URL}/logo/create`,
      GET_LOGO_DETAIL: `${API_BASE_URL}/logo/detail`,
      POST_LOGO_SET_TAGS: `${API_BASE_URL}/logo/setTags`,
      POST_LOGO_DELETE: `${API_BASE_URL}/logo/delete`,
      POST_LOGO_INSERIALS: `${API_BASE_URL}/logo/inSerials`,

      GET_EMOTION_LIST: `${API_BASE_URL}/emotion/list`,
      GET_PUBLIC_EMOTION_LIST: `${API_BASE_URL}/emotion/listPublic`,
      POST_EMOTION_CREATE: `${API_BASE_URL}/emotion/create`,
      POST_EMOTION_UPDATE: `${API_BASE_URL}/emotion/update`,
      POST_EMOTION_DELETE: `${API_BASE_URL}/emotion/delete`,
      POST_EMOTION_INSERIALS: `${API_BASE_URL}/emotion/inSerials`,

      GET_SURVEY_LIST: `${API_BASE_URL}/survey/list`,
      POST_SURVEY_CREATE: `${API_BASE_URL}/survey/create`,
      POST_SURVEY_UPDATE: `${API_BASE_URL}/survey/update`,
      POST_SURVEY_DELETE: `${API_BASE_URL}/survey/delete`,

    }
  },
  getters: {
    axiosConfig(state, getters, rootState) {
      return {
        headers: {
          'token': rootState.auth.token,
          'Cache-Control': 'no-cache',
          // 'Content-Type': 'application/json',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postUploadFilePromise({getters}, {url, filename, file}) {
      const formData = new FormData();
      formData.append(filename, file);
      // const axiosConfig = {
      //   headers: {
      //     'token': getters.axiosConfig.headers.token,
      //     'Cache-Control': 'no-cache',
      //     'Content-Type': 'multipart/form-data',
      //   }
      // };
      const axiosConfig = JSON.parse(JSON.stringify(getters.axiosConfig)); //使用深拷貝, 使其不影響其他引用getters.axiosConfig的未知錯誤
      axiosConfig.headers['Content-Type'] = 'multipart/form-data';
      return new Promise(function(resolve, reject) {
        axios.post(url, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getApiPromise({getters}, {url}) {
      return new Promise(function(resolve, reject) {
        axios.get(url, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },

    postLoginPromise({dispatch, state}, {email, password}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGIN,
        data: {email, password}
      });
    },

    postLoginLinePromise({dispatch, state}, {code, callbackUrl}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGINLINE,
        data: {code, callbackUrl}
      });
    },

    postRefreshTokenPromise({dispatch, state}, token) {
      return dispatch('postApiPromise', {
        url: state.URLS.REFRESH_TOKEN,
        data: {token}
      });
    },

    getAccountInfoPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_ACCOUNT_INFO,
      });
    },

    /* survey */
    getSurveyDetail({dispatch, state}, id) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GET_SURVEY_DETAIL}/${id}`,
      });
    },


    /* record */
    postRecordAnswered({dispatch, state}, {logoSerial, emotionSerial, surveySerial, value}) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_RECORD_ANSWERED,
        data: {
          logoSerial, emotionSerial, surveySerial, value
        }
      });
    },

    getRecordListByLogoSerials({dispatch, state}, logoSerials) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GET_RECORD_LIST_BY_LOGOS}?s=${logoSerials.join(',')}`,
      });
    },

    


    /* logo */
    getLogoListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_LOGO_LIST,
      });
    },

    getPublicLogoListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_PUBLIC_LOGO_LIST,
      });
    },

    postUploadBase64ImgPromise({dispatch, state}, dataurl) {
      return dispatch('postApiPromise', {
        url: state.URLS.UPLOAD_LOGO_IMAGE,
        data: {
          img: dataurl,
        }
      });
    },

    postLogoCreatePromise({dispatch, state}, obj) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_LOGO_CREATE,
        data: obj
      });
    },

    getLogoDetailPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GET_LOGO_DETAIL}/${serial}`,
      });
    },

    postLogoSetTagsPromise({dispatch, state}, {serial, tags}) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_LOGO_SET_TAGS,
        data: {
          serial, tags
        }
      });
    },

    postLogoDeletePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_LOGO_DELETE,
        data: {
          serial
        }
      });
    },

    postQueryLogoBySerialsPromise({dispatch, state}, serials) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_LOGO_INSERIALS,
        data: {
          serials
        }
      });
    },

    

    /* emotion */
    
    getEmotionListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_EMOTION_LIST,
      });
    },
    
    getPublicEmotionListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_PUBLIC_EMOTION_LIST,
      });
    },

    postEmotionCreatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_EMOTION_CREATE,
        data,
      });
    },

    postEmotionUpdatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_EMOTION_UPDATE,
        data,
      });
    },

    postEmotionDeletePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_EMOTION_DELETE,
        data: {
          serial
        }
      });
    },

    postQueryEmotionBySerialsPromise({dispatch, state}, serials) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_EMOTION_INSERIALS,
        data: {
          serials
        }
      });
    },


    /* survey 問卷 */

    getSurveyListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_SURVEY_LIST,
      });
    },

    postSurveyCreatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_SURVEY_CREATE,
        data,
      });
    },

    postSurveyUpdatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_SURVEY_UPDATE,
        data,
      });
    },

    postSurveyDeletePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_SURVEY_DELETE,
        data: {
          serial
        }
      });
    },
  },
}
