<template>
  <nav>
    <router-link :to="{name: 'Home'}">Logo</router-link>
        
    <div class="nav-bottom">
      <router-link :to="{name: 'Login'}" v-if="!isLoggedIn">Login</router-link>
      <a href="#" @click.prevent="loggedOut" v-if="isLoggedIn">Logout</a>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'TopNav',
  data() {
    return {};
  },
  props: {

  },
  computed: {
    ...mapGetters(['isStoreBusy', 'auth/hasToken']),
    ...mapState(['hideNav']),
    isLoggedIn() {
      return this['auth/hasToken'];
    },
  }

}
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  justify-content: space-between;
}
</style>